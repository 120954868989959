const INFO = {
	main: {
		title: "Reactfolio by truethari",
		name: "Mohammadshabir",
		email: "Moshabir98@outlook.com",
		logo: "../newlogo.webp",
	},

	socials: {
		twitter: "https://youtube.com/@shabirshah336?si=VEjCRzZPCHOh0Ubr/",
		github: "https://github.com/sabaeshah/",
		linkedin: "https://linkedin.com/",
		instagram: "https://www.instagram.com/sabae_shah/profilecard/?igsh=MXhpY254czRxcjR4cg==/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Data Engineer and Analyst with part-time body builder profession.",
		description:
			"Moh",
	},

	about: {
		title: "Hey Mohammadshabir here i am data analyst and network infrastructure designer,with lots of new innovative ideas.",
		description:
			"I am passionate about leveraging technical expertise in data management, system configuration, and troubleshooting. Having completed an advanced diploma in Technical Systems Analysis and coursework in IT Infrastructure, I am enthusiastic about contributing to a dynamic organization in the field of IT and data analysis. I bring strong analytical skills, collaborative experience, and problem-solving abilities honed through roles in IT support and academic projects."
	,
	},
    education: [
		{
			school: "Conestoga College",
			program: "IT Infrastructure",
			duration: "March 2023 - Dec 2023"
		},
		{
			school: "Fanshawe College, London",
			program: "Technical Systems Analyst",
			duration: "Expected Dec 2024"
		}
	],
	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Project 1",
			description:
				"Utilized virtualization tools (VMware) to configure servers and client machines, simulating a real-world IT infrastructure.",
			logo: "https://cdn.brandfetch.io/vmware.com/w/512/h/84/logo",
			linkText: "View Project",
			link: "https://github.com/sabaeshah/",
		},
    
		{
			title: "Project 2",
			description:
				" Gained hands-on experience in SQL queries, database design, and performance tuning. Created a database system that tracks inventory, sales, and customer information for a mock retail company",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/sabaeshah/",
		},

		{
			title: "Project 3",
			description:
				"Assisted with the configuration and maintenance of network infrastructure, including routers and switches. Provided first-level technical support to employees, diagnosing hardware and software issues across multiple platforms",

			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com/sabaeshah/",
		},

		{
			title: "Project 4",
			description:
				"Provided technical support and troubleshooting for residential and small business customers, ensuring high-quality service delivery. Contributed to network stability through effective troubleshooting and system upgrades.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/sabaeshah/",
		},

		{
			title: "Project 5",
			description:
				" Developed a Java-based application to manage inventory for a small business. The application included features for tracking products, sales, and generating reports. Utilized Java's object-oriented programming principles to create a scalable and efficient solution",

			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/sabaeshah/",
		},
	],
};

export default INFO;
